<template>
  <div class="d-flex align-items-center">
    <div class="d-flex align-items-center" v-if="false">
      <label class="inline-label">Search</label>

      <b-input-group class="input-group-merge">
        <b-form-input v-model="searchTerm" placeholder="Cari data"
          @keyup.enter="$emit('keyupEnterSearch', searchTerm)" />
        <b-input-group-append is-text>
          <feather-icon class="text-light" icon="SearchIcon" />
        </b-input-group-append>
      </b-input-group>
    </div>

    <b-button v-b-toggle.advance-search-sidebar class="ml-1" variant="info">
      <feather-icon icon="SearchIcon" />
      <span class="d-none d-md-inline">Search</span>
    </b-button>

    <b-button v-if="addButton" class="ml-1" variant="danger" @click.prevent="$emit('addButtonAction')">
      <feather-icon icon="PlusIcon" />
      <span class="d-none d-md-inline">Add</span>
    </b-button>

    <!-- SIDEBAR -->
    <b-sidebar id="advance-search-sidebar" class="custom-sidebar" bg-variant="white" right shadow no-header>
      <template #default="{ hide }">
        <div class="sidebar-header">
          <h5 class="sidebar-title">
            Advance Search
          </h5>
          <b-button class="sidebar-close-btn" @click="() => handleCloseSidebar(hide)">
            <feather-icon class="text-body" icon="XIcon" size="20" />
          </b-button>
        </div>
        <div class="sidebar-content">
          <vue-form-json-schema v-model="model" :schema="schema" :ui-schema="uiSchema" />

          <b-row>
            <b-col md="12">
              <b-form-group label="Tanggal Acara" label-for="tanggalacara">
                <b-form-datepicker id="tanggalacara" v-model="model.tanggalacara" :locale="locale" today-button
                  reset-button :start-weekday="weekday" :show-decade-nav="showDecadeNav" :hide-header="hideHeader" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Sudah Ditelpon" label-for="cs_sudah_telp">
                <v-select id="cs_sudah_telp" v-model="model.cs_sudah_telp"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="telpoptionfield" label="text"
                  placeholder="Pilih" />
              </b-form-group>
            </b-col>

            <b-col md="12">
              <b-form-group label="Cabang" label-for="cabang">
                <v-select id="cabang" v-model="model.cabang" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cabangoptionfield" label="text" placeholder="Pilih" />
              </b-form-group>
            </b-col>
          </b-row>
          <div class="d-flex align-items-center">
            <b-button class="mr-75 text-white" block variant="dark" @click="resetAdvanceSearch(hide)">
              <feather-icon icon="RefreshCcwIcon" /> Reset
            </b-button>
            <b-button class="mt-0" block variant="primary" @click="handleAdvanceSearch(hide)">
              <feather-icon class="mr-50" icon="SearchIcon" /> Search
            </b-button>
          </div>
        </div>
      </template>
    </b-sidebar>
    <!-- SIDEBAR -->
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { BFormDatepicker, BRow, BCol, BFormGroup, BFormInput, BFormInvalidFeedback } from 'bootstrap-vue'
import { required } from '@validations'
import schema from './schema/SearchSchema.json'
import uiSchema from './schema/SearchUiSchema.json'
import vSelect from 'vue-select'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    vSelect,
    BFormInvalidFeedback,
    BFormDatepicker,
  },
  props: {
    addButton: {
      type: Boolean,
    },
  },
  data() {
    return {
      searchTerm: '',
      model: {
        namapemesan: '',
        nohp: '',
        alamat: '',
        token: '',
        cs_sudah_telp: '',
        tanggalacara: '',
        cabang: '',
      },
      telpoptionfield: ['Sudah', 'Belum', 'Semua'],
      cabangoptionfield: [],
      schema,
      uiSchema,
      context: null,
      showDecadeNav: false,
      hideHeader: false,
      locale: 'en-US',
      locales: [
        { value: 'en-US', text: 'English US (en-US)' },
        { value: 'de', text: 'German (de)' },
        { value: 'ar-EG', text: 'Arabic Egyptian (ar-EG)' },
        { value: 'zh', text: 'Chinese (zh)' },
      ],
      weekday: 0,
      weekdays: [
        { value: 0, text: 'Sunday' },
        { value: 1, text: 'Monday' },
        { value: 6, text: 'Saturday' },
      ],
    }
  },
  mounted() {
    this.getCabangLists()
    this.model.cs_sudah_telp = 'Semua'
  },
  methods: {
    handleAdvanceSearch(hide) {
      const params = {
        namapemesan: this.model.namapemesan,
        nohp: this.model.nohp,
        alamat: this.model.alamat,
        token: this.model.token,
        cs_sudah_telp: this.model.cs_sudah_telp,
        tanggalacara: this.model.tanggalacara,
        cabang: this.model.cabang ? this.model.cabang.value : '',
      }

      this.$emit('handleAdvanceSearch', params)
      this.handleCloseSidebar(hide)
    },
    resetAdvanceSearch(hide) {
      this.model = { Keyword: '' }
      this.$emit('resetAdvanceSearch', this.model.Keyword)
    },
    handleCloseSidebar(hide) {
      hide()
    },
    async getCabangLists() {
      const params = {
        page: 1,
        perPage: 100000
      };

      const response = await this.$http.get('/api/cabang/list', { params: params })
      response.data.data.forEach(reason => {
        const cabangOption = { text: reason.namacabang, value: reason.namacabang };
        if (!this.cabangoptionfield.some(opt => opt.value === cabangOption.value)) {
          this.cabangoptionfield.push(cabangOption);
        }
      });
    },
  },
}
</script>